import { SponsorShow } from "../../components";

export const Sponsor = () => {
  return (
    <div className="flex flex-col p-5 md:p-10 lg:items-center">
      <div className="sm:w-full md:2/3 lg:w-5/6">
        <SponsorShow />
      </div>
    </div>
  );
};

export default Sponsor;
