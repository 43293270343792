/* eslint-disable no-useless-concat */
import {
  AkiraMitsumasu,
  AndrewWinston,
  AndyWilson,
  JennyAndersson,
  KyungKonKo,
  MarcBuckley,
  MartinRich,
  ThomasKloster,
  DanielAronson,
  DanielKrohn,
  DarrenBeck,
  DrDangjaithawinAnantachai,
  DrSirikulLaukaikul,
  GeraldMiranda,
  GloytaNathalang,
  JakkrinTaepaisitpong,
  KanitaTungwarapojwitan,
  KeiichiUshijima,
  KoAnnVikorenSkrzyniarz,
  KulvechJanvatanavit,
  MichelleLim,
  MinGuJun,
  MohamedAdamWeeAbdullah,
  NongchanokStananonth,
  SakulthipKeeratiphanthawong,
  ShinsukeSuzuki,
  SongpolShanmatkit,
  VenusAsavasitthithavorn,
  YKPark,
  YukiMatsumoto,
} from "../../static/images";

const avatar = {
  avatar: [
    {
      image: AkiraMitsumasu,
      name: "Akira Mitsumasu",
      header1:
        "Vice President, Global CX & Marketing, CX, Data Analytics & Marketing",
      header2: "Japan Airlines",
      detail: [
        "Akira brings over 30 years of experience in the aviation industry and has held multiple leadership roles within Japan Airlines, including his previous role as VP of Products and Services. Akira has been named one of Asia’s Best Marketers and is recognized as one of the 50 Most Influential Global Marketing Leaders. He currently serves as an advisory board member of the CMO Council for Asia Pacific, as a member of EFFIE Asia Pacific Council, and as a member of the APEX (Airline Passenger Experience) board of directors.",
      ],
      subDetail: [],
    },
    {
      image: AndrewWinston,
      name: "Andrew Winston",
      header1: "Manager",
      header2: "Winston Eco-Strategies, LLC, The Author of “Net Positive”",
      detail: [
        "Andrew Winston is a globally-recognized expert on megatrends and how to build companies that thrive by serving people and the planet. He is one of the most widely read writers on sustainable business in the world, regularly contributing columns in the Harvard Business Review and MIT Sloan Management Review.",
        "Andrew’s latest book, Net Positive: How Courageous Companies Thrive by Giving More than They Take (co-authored with renowned CEO Paul Polman), is one of the Financial Times’ Best Business Books of the Year. Net Positive has been called an “electrifying strategy for business success and unlike any other book you’ve read” by Merck Chairman Ken Frazier, a “wonderful rallying call” by Sir Richard Branson, and “pure heresy” by Arianna Huffington.",
        "Andrew’s first book, Green to Gold, has reached more than 100,000 people in seven languages. Inc. Magazine included Green to Gold on the magazine’s all-time list of 30 books that every manager should own.  In addition to this, his book The Big Pivot was selected as one of the " +
          '"Best Business Books"' +
          " by Strategy+Business magazine. Winston is also the author of the Harvard Business Review (HBR) Magazine cover story, “The Net Positive Manifesto” and the HBR “Big Idea” cover story, “Leading a New Era of Climate Action.” His views on strategy have been sought after by many of the world’s leading companies, including 3M, DuPont, HP, J&J, Kimberly-Clark, Marriott, PepsiCo, PwC, Trane Technologies, and Unilever.",
        " Andrew was selected for the Thinkers50 list of the top management thinkers in the world. He has appeared in major media such as Bloomberg, The Wall Street Journal, Time, BusinessWeek, New York Times, and CNBC. Andrew is a highly respected and dynamic speaker, reaching audiences of thousands with an entertaining message of practical optimism: the world’s challenges are great, but business has the tools, resources, and creativity to build a thriving world. He has spoken on 5 continents, bringing his inspiration to leadership meetings of the top executives of multinationals, to large industry conferences, and to high-profile events like the World Innovation Forum and TED.",
        "  Andrew’s work is based on significant business experience and education.  His early career included advising companies on corporate strategy while at Boston Consulting Group and management positions in strategy and marketing at Time Warner and MTV. He received his BA in Economics from Princeton, an MBA from Columbia, and a Masters of Environmental Management from Yale. He lives in Greenwich, CT with his wife and two sons.",
      ],
      subDetail: [],
    },
    {
      image: AndyWilson,
      name: "Andy Wilson",
      header1: "Senior Partner",
      header2: "Ogilvy",
      detail: [
        "Andy Wilson was Chief Strategy Officer at BBDO Asia for 11 years before joining Ogilvy in January 2022 to lead their Sustainability Practice. He works with companies to shape environmentally and socially sustainable solutions and practices in the region.",
        "A visionary brand marketing leader, culture creator and organisation transformer, Andy has an exceptional track record of building businesses by transforming brands and internal cultures for clients in every sector, across global, regional and local markets for over 20 years.",
        "He has a deep understanding of behavioral changes; contemporary corporate culture, consumer psychology and neuroscience, combined with powerful and unique conceptual, creative and storytelling capabilities.",
        "Andy has worked with the world’s leading corporate and product brands in all sectors including FMCG (Unilever, Procter & Gamble, Johnson & Johnson); B2B (FedEx, SAP, Grobest); financial services (Visa, AIA, American Express); auto (Mercedes, Ford, Exxon); food and beverages (Pepsi, Mars, Diageo, Coca Cola, Fonterra); consumer durables (HP, LG, Motorola, Blackberry); lifestyle (Nike; Vitality; Singtel).",
        "He has authored multiple thought leadership articles and papers and is a regular contributor to industry events, learning and development courses",
      ],
      subDetail: [],
    },
    {
      image: JennyAndersson,
      name: "Jenny Andersson",
      header1: "Founder",
      header2: "Really Regenerative CIC",
      detail: [
        "Jenny is a regenerative practitioner, strategist and agent for change.  She walks side by side with brands, organisations, cities and municipalities who want to be part of the movement to create a new regenerative economy.  As a strategist, facilitator, and educator, she harnesses the power of the collective intelligence of organisations and communities to create visions for the future they want – together – and finds the vitalising energy, psychological safety and will to sustain long term change.",
        "She cares deeply about healing the story of separation between humanity and nature, and between humans themselves through divisive cultural, social and economic barriers. She believes strongly in the power of connection between people and places. Jenny believes her work helps to surface the bio-culturally unique stories of people and places which set and sustain the vision to travel to tomorrow’s regenerative future.",
        "She is frequently invited to give keynote talks at events where navigating the journey towards an uncertain future is a key exploration.  She speaks at global sustainability events, gives customised talks at organisational annual conferences, and is regularly invited to speak as a panellist. She participates in webinars and workshops, and is the host of Connectle’s Regenerative Business global webcast series.",
        "She brings complex issues to life with engaging storytelling, well-researched data and facts, and helps audiences to engage with the major systems shifts we need to undertake to create a regenerative future for all.",
        "Almost four decades of work with purpose-led global brands like Patagonia and Timberland, ambitious start-ups seeking to activate whole systems change, and working in her own back yard Sussex in England, she brings a unique perspective on how global systems affect us all – locally – and how and why we need to change it.",
        "The Emergent Regenerative Economy",
      ],
      subDetail: [],
    },
    {
      image: KyungKonKo,
      name: "Kyung-Kon Ko",
      header1: "President & CEO",
      header2: "Daejeon Tourism Organization (DJTO)",
      detail: [
        "Learned and acquired to be more responsive to changing consumer needs, more attuned to the macro trends driving market opportunities, more interactive in customer relationship management and more nimble in how to strategize, assign resources and build marketing programs to support rapid, continuous innovation.",
        "Marketing specialist/generalist with substantial experience.  Interested in innovation in marketing.",
        "Held leadership roles in on-line CS, on-line sales channel, brand building, sports marketing, digital marketing, integrated marketing communication and game publishing.",
        "Committed for people capability building and performance management.",
        "Extensive industry experience in Consumer Products, Electronics (LCD TV, Ref, W/M, A/C), Mobile, Internet and online game",
      ],
      subDetail: [
        "Specialties:",
        "New Business Development",
        "On-line Platform",
        "Marketing Communication",
        "Sports Marketing",
        "Digital Marketing",
        "Brand Marketing",
        "Media Management",
        "Marketing Strategy and Planning",
        "Project Management",
      ],
    },
    {
      image: MarcBuckley,
      name: "Marc Buckley",
      header1: "Systems Expert & Ecological Economist",
      header2: "UN, WEF, Inside Ideas, & ALOHAS Regenerative Foundation",
      detail: [],
      subDetail: [],
    },
    {
      image: MartinRich,
      name: "Martin Rich",
      header1: "Co-Founder & Executive Director",
      header2: "Future-Fit Foundation",
      detail: [
        "Martin Rich is a world leading sustainability and impact specialist, with over 25 years’ experience in both mainstream and social investment. He is renowned around the globe for his humorous and passionate talks, and has been called a radical innovator, futurist, international thought leader and solution builder.",
        "Following a successful career in international finance working for UBS, JPMorgan, and HSBC, he co-founded Future-Fit Foundation with a vision to create a truly sustainable future for all. He now works on the cutting-edge of sustainability, helping companies and financial institutions of all sizes address the radical transformation that the future demands of their business models.",
        "Martin holds two Master’s degrees with Distinction in Engineering from Queens' College, Cambridge University.",
      ],
      subDetail: [],
    },
    {
      image: ThomasKloster,
      name: "Thomas Kolster",
      header1: "Speaker, Author, Marketing Activist and Purpose Pioneer",
      header2: "Goodvertising",
      detail: [
        "Thomas Kolster is a marketing activist on a mission to make businesses put people and the planet first. ",
        "As a seasoned branding and sustainability professional of more than 18 years’ standing, he has advised Fortune 500 companies, small startups, governments, agencies, and nonprofits.  He’s the founder of the global Goodvertising movement that has inspired a shift for the better in advertising.  He is also the author of two books: ‘Goodvertising’ (Thames & Hudson 2012) and ‘The Hero Trap’ (2020).",
        "He is an internationally recognised keynote speaker who has appeared in more than 70 countries at events like TEDx, SXSW, D&AD & Sustainable Brands, and is a columnist for the Guardian, Adweek, The Drum and several other publications.  He has also regularly judged at international award shows such as Cannes Lions and D&AD.  As a passionate entrepreneur and change agent he has launched several impact platforms like Cph:Change and Wheregoodgrows.  Thomas’ belief is simple: “Change begins with you!”",
      ],
      subDetail: [],
    },
    {
      image: DanielAronson,
      name: "Daniel Aronson",
      header1: "Founder",
      header2: "Valutus, The Author of " + '"The Value of Values"',
      detail: [],
      subDetail: [],
    },
    {
      image: DanielKrohn,
      name: "Daniel Krohn",
      header1: "International & Brand Transformation Consultant",
      header2: "SB Worldwide",
      detail: [
        "Daniel Krohn has been working in the sustainability field for more than 13 years. He currently is the International Partnerships Manager for Sustainable Brands. He manages the international line of business for Sustainable Brands where he works closely with all of the company’s international teams. Prior to his current position, Daniel worked as the Director of Global Sustainability for an agriculture company, Becker Underwood/BASF. He has an MBA and a BA in biology. Daniel has additional training and education in sustainable business practices from the Institute of Design at Stanford University, Harvard Business School Executive Education in Corporate Social Responsibility, One Planet Leaders (World Wildlife Fund), GRI Sustainability Reporting Certification, Natural Resources Canada (NRC), and Point Carbon – Carbon Trading.",
      ],
      subDetail: [],
    },
    {
      image: DarrenBeck,
      name: "Darren Beck",
      header1: "Vice President, Membership & International",
      header2: "SB Worldwide",
      detail: [
        "Darren Beck is the vice president of membership and international at Sustainable Brands, where he has helped hundreds of companies leverage their purpose to reduce business risk, enhance brand and reputation, identify new market opportunities, and boost their bottom line. Darren combines his experience in environmental and social innovation, having helped establish one of the country’s premier corporate sustainability programs.  He also has a unique perspective rooted in advertising, marketing, and sales to help brands thrive",
      ],
      subDetail: [],
    },
    {
      image: DrDangjaithawinAnantachai,
      name: "Dr. Dangjaithawin Anantachai",
      header1: "Managing Director & COO",
      header2: "INTAGE Thailand, SB Thailand",
      detail: [
        "Orm is one of Thailand’s foremost marketing research consultants and coaches/trainers in the area of consumer psychology and insight. With more than 25 years of experience, she has helped clients redefine value proposition based on the outside-in & latent needs of consumers as well as competitive choice analysis. She was the president of the Thai Marketing Research Society and Asia Pacific Research Committee during 2013-2014 and now serves as a committee member of the Marketing Association of Thailand driving sustainability development among Thai CMOs.",
      ],
      subDetail: [],
    },
    {
      image: DrSirikulLaukaikul,
      name: "Dr. Sirikul Laukaikul",
      header1: "Country Director",
      header2: "SB Thailand",
      detail: [
        "Dr. Sirikul established her own consulting firm after working for global corporations for  nearly twenty years. The mission of her firm is to be a small but competent strategic advisory company with the aim of working with Thai clients who truly believe in sustainable branding and systematic methodology. Her approach to developing brand strategy is based upon the Sufficiency Economy Philosophy.",
        "Her background ranges from communication to management, to sustainable  development.  This together with her own unique combination of creative flair and strategic thinking make her a ‘one of a kind’ consultant.  Dr. Sirikul can provide strategic and holistic  recommendations that are sensible and practical for each client’s issues. Her strength is her expertise in compelling brand strategy development—Brand Model & Brand Architecture as well as Branded CSR.",
        "She has been representing SB (Sustainable Brands) in Thailand since 2016, and has successfully organized conferences among business circles for more than 6 years. In addition to SB, she initiated the “พอแล้วดี The Creator” program, aimed at strengthening immunity among young entrepreneurs with Sufficiency Economy Philosophy.",
      ],
      subDetail: [],
    },
    {
      image: GeraldMiranda,
      name: "Gerald Miranda",
      header1: "Country Director",
      header2: "SB Malaysia",
      detail: [],
      subDetail: [],
    },
    {
      image: GloytaNathalang,
      name: "Gloyta Nathalang",
      header1:
        "Executive Vice President, Corporate Sustainability, Branding and Communication",
      header2: "Bangchak Corporation Public Company Limited",
      detail: [
        "Gloyta is a professional communicator with a passion in communications and sustainable development. ",
        "She is currently responsible for internal, external and executive communications, corporate branding, corporate affairs, and strategic integration of sustainability across the Bangchak Group.",
      ],
      subDetail: [],
      describe: {
        describTitle1: "Education / Training",
        describe1: [
          "Bachelor of Arts, Chulalongkorn University",
          "Postgraduate Diploma in Public Relations, University of Stirling, UK",
          "Shell External Affairs Training Series (2000), UK ",
          "Tetra Pak Communications Leadership Training (2007), Dubai",
          "Tetra Pak Academy Leadership Program by Ashridge Business School (2008), UK",
          "Tetra Pak Environmental Leadership Training (2011), Sweden ",
          "Executive Development Program (18/2019), Thai Listed Company Association (TLCA)",
          "McKinsey Management Program (MMP) 2020, TLCA & McKinsey ",
          "Strategic Executive Program, Public Communication in Broadcasting and Television Affairs (1/2020), ISRA Institute Thai Press Development Foundation Executive Development Program",
        ],
        describTitle2: "Experience",
        describTitle3: "Bangchak Corporation Public Company Limited",
        describe3: [
          {
            year: "2021 (present)",
            data: "Executive Vice President, Corporate Sustainability, Branding and Communication",
          },
          {
            year: "2020",
            data: "Acting Executive Vice President, Corporate Sustainability, Branding and Communication",
          },
          {
            year: "2019",
            data: "Senior Vice President, Corporate Branding and Communication",
          },
        ],
        describTitle4: "Others",
        describe4: [
          {
            year: "2016",
            data: "Senior Vice President, Corporate Communication, BCPG Plc.",
          },
          {
            year: "2015",
            data: "General Manager, Tea The' Cha (Thailand) Co., Ltd.",
          },
          {
            year: "2013",
            data: "Communications Director, Corporate Functions, Tetra Pak International S.A.,     Switzerland",
          },
          {
            year: "2007",
            data: "Communications and Environment Director, Tetra Pak Thailand Ltd.",
          },
          {
            year: "2005",
            data: "Communications and PR Manager, McDonald’s Thailand",
          },
          {
            year: "2004",
            data: "External Affairs Manager, Thai Shell Exploration and Production Co., Ltd.",
          },
          {
            year: "2000",
            data: "Communications Manager, Thai Shell Exploration and Production Co., Ltd.",
          },
        ],
        describTitle5: "Other positions at present",
        describe5: [
          "Director, Baimai Punsuk (Happiness Sharing) Foundation",
          "Director, Oam Suk Social Enterprise Company Limited",
          "Chairperson, Carbon Markets Club ",
          "Communications Subcommittee on Resolving Air Pollution Problems, National Environment Board",
          "Committee, Global Compact Network Thailand",
          "Committee, Thailand Carbon Neutral Network",
        ],
      },
    },
    {
      image: JakkrinTaepaisitpong,
      name: "Jakkrin Taepaisitpong",
      header1:
        "Executive Vice President, Social & Sustainable Development Office",
      header2: "Betagro Public Company Limited",
      detail: [
        "Mr. Jakkrin Taepaisitpong is the EVP of Corporate Social Responsibility and Sustainable Development.",
      ],
      subDetail: [],
      describe: {
        describTitle2: "Experience",
        subdeScribe: [
          {
            title: "Betagro Public Company Limited",
            detail: [
              "2022 (present) EVP of Corporate Social Responsibility and Sustainable Development",
              "2021 EVP of Corporate Administration ",
              "2020 (since 2017) SVP of Corporate Social Contribution and Corporate Social Responsibility",
              "2016 (since 2002) SVP of Poultry business to leverage the whole integration such Production, Broiler, Slaughter House and Further Processing Plant to meet the annual budget",
            ],
          },
          {
            title: "Education",
            detail: [
              "Mr. Jakkrin received a bachelor's degree in marketing from The University of the Thai Chamber of Commerce, Bangkok, Thailand in 1996, and a master’s degree in International Economic and Political Assessment from Dominican University, CA, USA in 1993.",
            ],
          },
        ],
      },
    },
    {
      image: KanitaTungwarapojwitan,
      name: "Kanita Tungwarapojwitan",
      header1: "SB Thailand",
      header2: "",
      detail: [
        "Kanita has a bachelor’s degree, Hons. in English literature and linguistics from faculty of arts, Chulalongkorn University and master’s degree in marketing (MIM in English). As the deputy managing director of INTAGE Thailand, Thailand’s branch of INTAGE Holdings Group, the number 1 research and insight agency in Asia, she initiated the launch of digital tools of INTAGE Thailand, AMP – Asia mobile & digital panel and CEI (customer engagement investigation using big data & customer analytics to measure customer engagement and NPS). This made INTAGE Thailand the top-notched digital research & insight agency in Thailand. Over the past two decades she has worked and gained brand and marketing consulting experience while working at Dentsu Young & Rubicam (DY&R) Thailand, Ogilvy Advertising, and McCann Worldgroup. She has played a part in building up global brands from scratch. With her experience she has shared her knowledge as a speaker and trainer on topics such as consumer insight, segmentation-targeting-positioning, content & influencer marketing, and customer experience (CX) marketing. Over the past 10 years she has spoken to many university students, SMEs, marketers, and top management throughout Thailand and Asia. She emphasises on the belief of “Sufficiency Economy Philosophy” to successfully build brand and business growth sustainably with guaranteed impact measurement.",
      ],
      subDetail: [],
    },
    {
      image: KeiichiUshijima,
      name: "Keiichi Ushijima",
      header1:
        "Partner, Japan CCaSS Leader, Climate Change and Sustainability Services (CCaSS)",
      header2: "EY",
      detail: [
        "Mr. Ushijima is responsible for leading sustainability advisory business and assurance services for non-financial disclosure as Japan Regional Leader of EY Climate Change and Sustainability Services.  Prior to joining EY, he led the global sustainability strategy at Hitachi, one of Japan’s largest Japanese electronics companies.  At Hitachi, he developed the Hitachi Group’s sustainability strategy, ESG risk assessment scheme, human rights due diligence process, and facilitated stakeholder engagement around the world, integrating sustainability into Hitachi’s business strategy.",
        "Mr. Ushijima is a member of the Carbon Pricing Committee of the Central Environmental Committee, Ministry of Environment, Japan, and a part-time MBA faculty member of Tohoku University and Keio University. ",
      ],
      subDetail: [],
    },
    {
      image: KoAnnVikorenSkrzyniarz,
      name: "KoAnn Vikoren Skrzyniarz",
      header1: "Founder & CEO",
      header2: "SB Worldwide",
      detail: [
        "KoAnn is Founder and CEO of Sustainable Life Media, producers and conveners of the internationally respected Sustainable Brands community. Through Sustainable Brands, she and her team led the global conversation on how 21st century brands can and are delivering new business value through innovation for environmental and social purposes.  In addition to  overseeing the direction and strategy at SB, she writes and speaks around the world on the shift in consumer demand and the opportunity for brands to respond by changing the way they perceive their roles in society.  She currently serves as a faculty member for Harvard’s Leadership for Sustainability Exec Ed program and is on the external sustainability advisory council for P&G.  She was recently awarded the Hutchens medal from ASQ (American Society for Quality), which recognizes exceptional leaders in social responsibility and sustainability. ",
        "Prior to launching Sustainable Life Media in 2004, KoAnn founded and led a boutique management consultancy focusing on enabling breakthrough financial performance through purpose-driven leadership and improved organizational alignment.  Before this, she worked for 18 years in business-to-business media positions where she was responsible for driving responsible media properties in areas such as magazines, conferences, and trade shows.  Her work involved the use of natural resources (timber, paper, and  mining) and using various types of technology (digital video, computer game development, and the Internet.)  During her tenure she garnered extensive experience in launching and growing countless  product brands.  This included driving corporate brand effort and integrating it into global brands in various sectors.  She completed her media tenure as Senior Vice President of a multinational  division of United Business Media, overseeing a $50 million division and a 150-person team.",
      ],
      subDetail: [],
    },
    {
      image: KulvechJanvatanavit,
      name: "Kulvech Janvatanavit",
      header1: "CEO",
      header2: "Thai Institute of Directors Association (IOD)",
      detail: [
        "Kulvech Janvatanavit is now a CEO at IOD (Thai Institute of Directors Association). He has led CAC (Collective Action Coalition Against Corruption) under the supervision of IOD.  CAC is a private sector platform that addresses corruption issues in Thailand.  ",
        "He is currently a member of the Stock Exchange of Thailand’s Sustainability Award  Committee.  ",
        "Before working at IOD, Kulvech was a director at the Financial Institutions Policy Committee (FIPC), one of the main committees in the Bank of Thailand. The FIPC is responsible for setting prudential policies, regulations, and supervisory practices to ensure the safety and stability of Thailand’s financial institutions.  Kulvech was a partner at PwC, helping clients integrate sustainable development into their business operations. His team assisted several leading Asian multinational businesses in developing sustainability strategies to adapt to the ever-changing global business landscape.  ",
        "Kulvech holds a Master’s of Science in Real Estate Development (MSRED) from  Massachusetts Institute of Technology, USA, a Master of Business Administration (MBA)  in Finance and International Business from Sasin GIBA, and a Bachelor of Science (BS)  in Mechanical Engineering, Chulalongkorn University. ",
      ],
      subDetail: [],
    },
    {
      image: MichelleLim,
      name: "Michele Kythe Lim",
      header1: "President & CEO",
      header2: "Institute of Corporate Directors Malaysia (ICDM)",
      detail: [
        "Michele works with boards, the director community, regulators, and other key stakeholders in the corporate governance ecosystem to enhance board and director effectiveness in Malaysia.  She brings with her a wide spectrum of leadership and advisory experience, particularly on corporate governance-related matters. Under her leadership, ICDM has embarked on a series of director development programs, board advisory services, as well as research and advocacy projects to support the Securities Commission Malaysia (SC)’s vision to build and strengthen the country’s corporate governance culture. Michele holds an LLB Hons degree from the University of Wales, Aberystwyth, UK (1991) and is a Barrister-at-Law at Middle Temple, London, England.  She completed the Senior Management Development Program of Harvard Business School in 2009 and is also a Licensed Company Secretary.",
      ],
      subDetail: [],
    },
    {
      image: MinGuJun,
      name: "MinGu Jun",
      header1: "Country Director",
      header2: "SB Korea",
      detail: [
        "Latitude has undertaken practical in-market representation for global companies and helped to build strong and lasting profiles in the Korean market.",
        "The Latitude team played the role as an innovative influencer in the Korean sustainability sector by running the Sustainable Brands’s Korea Chapter (sustainable brands.kr), providing most up-to-date advisory and training services, and hosting a series of sustainability & innovation conferences from 2016.",
      ],
      subDetail: [],
    },
    {
      image: MohamedAdamWeeAbdullah,
      name: "Mohamed Adam Wee Abdullah",
      header1: "CMO",
      header2: "Manulife Insurance Berhad",
      detail: [
        "Adam is a veteran in marketing across multi-disciplines with a career spanning 30 years in international brand agencies, MNCs, and GLCs. He spent the last decade in the financial services industry.  Prior to Manulife, Adam was the GCMO and GCCXO of CIMB Group and  ",
        "Maybank Group, CMO of Sunrise Bhd, and Brand & Advertising Director of ASTRO.  His experience in regional marketing management with BMW Group covered responsibilities in 17 countries across India, China, South-East Asia, and the South Pacific Island Nations. He is an advocate of Martech and modernizing marketing and is active as a speaker and thought leadership content contributor on the topic. Adam also serves as an Industry Advisory Board member with Sunway University Business School and APAC Advisory Board Member with Insider Global.  Adam was previously a council member of the Malaysian Advertisers Association (MAA) and an Executive Committee member of the World Federation of Advertisers (WFA).",
      ],
      subDetail: [],
    },
    {
      image: NongchanokStananonth,
      name: "Nongchanok Stananonth",
      header1: "General Manager",
      header2: "The Coffee Club Thailand",
      detail: [
        "With dedication and outstanding achievements, in 2005 Mrs. Nongchanok was appointed as Marketing Director of Sizzler Thailand and China Sizzler to oversee the marketing of Sizzler brands in both countries and in 2010 was appointed as Assistant Vice President of Marketing Group.  She was also responsible for the marketing of Burger King in Thailand. After being appointed to take over the brand of The Pizza Company in late 2012, she returned to taking care of the Sizzler brand (Steak & Salad Bar) Thailand and China in June 2013.  Most recently in December 2021, she has been assigned to oversee The Coffee Club Thailand (Coffee & Café) as General Manager.",
      ],
      subDetail: [],
      describe: {
        subdeScribe: [
          {
            title: "Present",
            detail: [
              "Won Gold award of Best Marketing Campaign “Sizzler” ",
              "Won Gold award in 2017 & won outstanding award 2019 of Global Minor Sustainability Award ",
              "Winner of YWN Awards 2020 from AMF: She is one of the leading marketers, not only in Thailand but Asia. Recently, she was awarded to be Asia’s Top Outstanding Women Marketeer of the Year 2020 of AMF. ",
              "Member of CMO Council Thailand (by Marketing Association of Thailand)",
              "Subcommittee National Blood Center, Red-Cross Thailand (nonprofit organization)",
            ],
          },
          {
            title: "Before joining Minor Group ",
            detail: [
              "Mrs. Nongchanok has experience working in the creation of many famous consumer brands from abroad and has made them known to Thai consumers by working with famous advertising companies in Thailand.  The agencies she has worked with include Ogilvy & Mather (Thailand) Co., Ltd., Spa Advertising Co., Ltd., Lintas (Thailand). Ltd., and DDB Needham (Thailand) Co., Ltd.",
            ],
          },
          {
            title: "Education",
            detail: [
              "Mrs. Nongchanok received a bachelor's degree, honors, and a master's degree from the Faculty of Communication Arts, Chulalongkorn University and in her leisure time likes traveling with family, watching movies, reading, shopping, and swimming.",
            ],
          },
        ],
      },
    },
    {
      image: SakulthipKeeratiphanthawong,
      name: "Sakulthip Kiratiphantawong",
      header1: "Executive Director",
      header2: "B Corp Thailand",
      detail: [
        "With experience in promoting business practices with social responsibility and corporate governance in the Thai capital market, Sakulthip is currently the co-founder and managing director of NISECorp, a social enterprise in Thailand and a pioneer in developing the social value ecosystem in Thailand. She is also the secretary-general of the Social Value Thailand Association and an associate member of Social Value International (SVTH is a non-profit organization which aims to promote social value accounting standard and professional development in Thailand). In addition, she is driving the BCORP movement in Thailand and the Certified B Corporations (B Corps), which are companies verified by B Lab to meet social sustainability and environmental performance, transparency, and accountability. Last but not least, she is also the former secretary-general and founding member of WorkabilityThailand(WTH), a non-profit organization that aims to promote competitive employment for people with disabilities and vulnerable groups in Thailand and across Asia.",
      ],
      subDetail: [],
    },
    {
      image: ShinsukeSuzuki,
      name: "Shinsuke Suzuki",
      header1: "Country Director",
      header2: "SB Japan",
      detail: [
        "After graduating from the Faculty of Science and Engineering at Waseda University, Suzuki joined Recruit Holdings Co., Ltd. where he was involved in IT service company startups.",
        "Suzuki later established a promotions company specializing in the IT industry.  Over the company’s 20 years, Suzuki has been involved in the marketing and communications, brand, PR, and sales strategy planning of over 100 IT companies.",
        "In 2012, Suzuki became a director at Hakuten Corporation where he oversaw new business development, digital business, and global strategy based on the concept of experience marketing.",
        "In March 2015, Suzuki met SB founder KoAnn and later in June 2015 participated in the SB San Diego Conference. Inspired by the growing global trend of sustainable brands, he dedicated himself to spreading these activities to Japan.",
      ],
      subDetail: [],
    },
    {
      image: SongpolShanmatkit,
      name: "Songpol Shanmatkit",
      header1: "CEO",
      header2: "TV Direct Public Company Limited",
      detail: [
        "Mr. Songpol Shanmatkit is the Chief Executive Officer, TV Direct Public Co., Ltd. He is also the Chairman of ABPO Co., Ltd and Founder of Food Ordery Co., Ltd. Songpol has experience in Direct Marketing since 1992",
      ],
      subDetail: [],
      describe: {
        describTitle3: "Experience",
        describe3: [
          {
            year: "2022 (present)",
            data: "Chief Executive Officer, TV Direct Public Co., Ltd Chairman of ABPO Co., Ltd",
          },
          {
            year: "2020 (since 2009)",
            data: "Member Federal Global Direct Marketing for Thailand ",
          },
          {
            year: "2019 (since 2011)",
            data: "Former Asia Committee of ERA, US ",
          },
          {
            year: "2018 (since 2008)",
            data: "Former Customer Protection Committee",
          },
          {
            year: "2017 (since 2008)",
            data: "Former Committee, the Office of the Consumer Protection Board",
          },
          {
            year: "2008 (since 2001)",
            data: "Former Chairman of Thailand Direct Market Association Former Assumption Commercial College Alumni Association (ACCA)",
          },
          {
            year: "2008 (since 2004)",
            data: "Former Chairman of TV Home Shopping Association (Thailand)",
          },
        ],
      },
    },
    {
      image: VenusAsavasitthithavorn,
      name: "Venus Asavasitthithavorn",
      header1: "Director, Enterprise Brand Management Office",
      header2: "SCG",
      detail: [""],
      subDetail: [],
      describe: {
        describTitle2: "Experience",
        subdeScribe: [
          {
            title: "Present",
            detail: [
              "Present  - Director, Enterprise Brand Management Office, SCG",
              "2015-Present - Secretary to Corporate Social Responsibility for Sustainable Development Committee, SCG ",
              "1986-2005 - Country Communication Manager, Michelin Siam Group",
            ],
          },
          {
            title: "Social Responsibility",
            detail: [
              "2018-Present  - Chairman of the Anti-Corruption and Ethics Committee, The Thai Chamber of Commerce",
              "2013-Present  - Director of Corporate Communications Committee, Thai Chamber of Commerce",
              "2017-Present  - Member of Corporate Governance Committee, Ministry of Commerce ",
              "2018-Present  - Member of Anti-Corruption Commission Subcommittee, (NACC, Nonthaburi)",
            ],
          },
          {
            title:
              "Performance of Corporate Social Responsibility for Sustainable Development",
            detail: [
              "2007-Present  - Water Conservation Project; From Mountian to the Mighty River",
              "2020-Present  - Social Contribution Project; Innovation for COVID-19",
              "2560-Present  - Mini MBA: Up Business Skill for Community Project",
              "2557-Present  - Sharing the dreams Project in ASEAN (Scholaship) ",
              "2554-2560       - Social Contribution Project; Flood Relief",
            ],
          },
        ],
      },
    },
    {
      image: YKPark,
      name: "Yoo-Kyung (YK) Park",
      header1: "Head of APAC Responsible Investment & Governance",
      header2: "APG Asset Management",
      detail: [
        "YK Park is the head of the Responsible Investment & Governance, Asia Pacific team at APG Asset Management Asia.  Her main role is to oversee portfolios and implement Responsible Investment Policies on behalf of APG’s clients.  The asset classes under her direct responsibility include: listed equities and listed real estate.  Her geographic coverage is the whole Asia Pacific region including Japan and Australia.  She actively engages with portfolio companies, market regulators/exchanges, and peer institutional investors on a variety of issues such as corporate governance, environmental, social, climate change issues.",
        "Before moving to Hong Kong from Seoul in 2006, YK spent over 10 years as a securities analyst working for international investment banks such as Barings Securities and Salomon Smith Barney (now Citigroup). She had covered a range of industry sectors including power & gas utilities, consumer, transportation, and heavy industries such as steel, chemicals and refining. ",
      ],
      subDetail: [],
    },
    {
      image: YukiMatsumoto,
      name: "Yuki Matsumoto",
      header1: "International Council of SB Advisory Board Members",
      header2: "SB Japan",
      detail: [
        "Yuki studied abroad was at the age of 16 years old on her own in Canada.",
        "She has spent about 8 years and graduated high school, and also studying business administration. After graduating, she worked for an event marketing company and",
        "has been involved in the marketing and communications, brand, PR, and sales strategy planning of over 50 IT companies.",
        "In 2012, she joined HAKUHEN Corporation. She has been involved in the launch of Sustainable Brands Japan since 2015. Since 2020, she is also the International Council of Advisory Board Members of Sustainable Brands.",
      ],
      subDetail: [],
    },
  ],
};

export default avatar;
